// Font
$font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base: 'Roboto', $font-family-sans-serif;
$font-size-base: 0.875rem;


// Import variables from xFrame4
@import "../../xFrame4/scss/variables";

// Colors: main
$primary: #34495e;
$secondary: #95a5a6;

// Colors: other

// Colors: misc
$min-contrast-ratio: 2.5;


// Buttons
$btn-border-radius: 0;